import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import supabase from '../supabaseClient';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { verifyEmail } = useAuth();

  useEffect(() => {
    const handleEmailVerification = async () => {
      try {
        const token = searchParams.get('token');
        if (token) {
          setLoading(true);
          await verifyEmail(token);
          setMessage('Email verified successfully! You can now sign in.');
        }
      } catch (error) {
        console.error('Verification error:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    handleEmailVerification();
  }, [searchParams, verifyEmail]);

  const handleResendVerification = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: email
      });
      if (error) throw error;
      setMessage('Verification email resent successfully!');
    } catch (error) {
      console.error('Error resending verification:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-3xl shadow-lg p-8">
        <h1 className="text-3xl font-bold mb-6">Verify Your Email</h1>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        {message && (
          <div className="mb-4 p-3 bg-green-100 border border-green-400 text-green-700 rounded">
            {message}
          </div>
        )}

        <div className="space-y-4">
          <p className="text-gray-600">
            We've sent a verification email to <strong>{email}</strong>.
            Please check your inbox and click the verification link to complete your registration.
          </p>

          <div className="space-y-2">
            <button
              onClick={handleResendVerification}
              disabled={loading}
              className="w-full p-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Processing...' : 'Resend Verification Email'}
            </button>

            <Link
              to="/sign-in"
              className="block w-full p-2 text-center text-blue-600 hover:text-blue-800"
            >
              Back to Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 