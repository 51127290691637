import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import PropTypes from 'prop-types';

export default function ProtectedRoute({ children, requireAuth = true }) {
  const { user } = useAuth();

  // If authentication is required and user is not logged in, redirect to sign-in
  if (requireAuth && !user) {
    return <Navigate to="/sign-in" replace />;
  }

  // If user is logged in and tries to access auth pages, redirect to home
  if (user && !requireAuth) {
    return <Navigate to="/" replace />;
  }

  // Otherwise, render the protected component
  return <>{children}</>;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  requireAuth: PropTypes.bool
}; 