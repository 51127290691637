import supabase from '../supabaseClient';
import axios from 'axios';
import md5 from 'md5';

// Debug log for environment variables
console.log('Mailchimp Environment Variables:', {
  apiUrl: process.env.REACT_APP_MAILCHIMP_API_URL,
  hasApiKey: !!process.env.REACT_APP_MAILCHIMP_API_KEY,
  listId: process.env.REACT_APP_MAILCHIMP_LIST_ID,
  welcomeWorkflowId: process.env.REACT_APP_MAILCHIMP_WELCOME_WORKFLOW_ID,
  nodeEnv: process.env.NODE_ENV
});

const mailchimpApi = axios.create({
  baseURL: process.env.REACT_APP_MAILCHIMP_API_URL,
  auth: {
    username: 'anystring',
    password: process.env.REACT_APP_MAILCHIMP_API_KEY
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

// Helper to get subscriber hash
const getSubscriberHash = (email) => md5(email.toLowerCase());

export const syncUserWithMailchimp = async (user, subscriptionStatus) => {
  try {
    console.log('Calling Mailchimp sync Edge Function:', {
      user: {
        email: user.email,
        full_name: user.full_name
      },
      subscriptionStatus
    });

    const { data, error } = await supabase.functions.invoke('mailchimp-sync', {
      body: { 
        user: {
          email: user.email,
          full_name: user.full_name
        }, 
        subscriptionStatus 
      }
    });

    if (error) {
      console.error('Edge Function error:', error);
      return false;
    }

    console.log('Mailchimp sync successful:', data);
    return true;
  } catch (error) {
    console.error('Error in syncUserWithMailchimp:', error);
    return false;
  }
};

export const updateUserTags = async (email, tags, status = 'active') => {
  try {
    const listId = process.env.REACT_APP_MAILCHIMP_LIST_ID;
    const subscriberHash = getSubscriberHash(email);

    const payload = {
      tags: tags.map(tag => ({
        name: tag,
        status: status
      }))
    };

    await mailchimpApi.post(`/lists/${listId}/members/${subscriberHash}/tags`, payload);
    return true;
  } catch (error) {
    console.error('Error updating Mailchimp tags:', error);
    return false;
  }
};

export const addToJourneyOrWorkflow = async (email, workflowId) => {
  try {
    const listId = process.env.REACT_APP_MAILCHIMP_LIST_ID;
    const subscriberHash = getSubscriberHash(email);

    // Add subscriber to a specific automation workflow
    await mailchimpApi.post(`/lists/${listId}/members/${subscriberHash}/actions/start-automation`, {
      workflow_id: workflowId
    });
    
    return true;
  } catch (error) {
    console.error('Error adding to Mailchimp workflow:', error);
    return false;
  }
}; 