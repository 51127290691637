// Analytics utility for GA4 event tracking

// Check if GA is loaded
const isGAAvailable = () => {
  return typeof window !== 'undefined' && window.gtag;
};

// Generic event tracking with error handling
export const trackEvent = (eventName, eventParams = {}) => {
  try {
    if (isGAAvailable()) {
      window.gtag('event', eventName, {
        ...eventParams,
        timestamp: new Date().toISOString(),
        page_path: window.location.pathname,
        page_url: window.location.href
      });
    }
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

// Conversion tracking
export const trackConversion = (conversionName, value = 0, currency = 'USD', additionalParams = {}) => {
  trackEvent(conversionName, {
    value,
    currency,
    conversion_type: conversionName,
    ...additionalParams
  });
};

// User engagement events
export const trackQuestionGeneration = (type, mood, complexity) => {
  trackEvent('generate_question', {
    question_type: type,
    mood: mood,
    complexity: complexity,
    event_category: 'engagement',
    event_label: `${type}_${mood}_${complexity}`
  });
};

export const trackQuestionVote = (questionId, voteType) => {
  trackEvent('question_vote', {
    question_id: questionId,
    vote_type: voteType,
    event_category: 'engagement',
    event_label: `vote_${voteType}`
  });
};

export const trackQuestionSave = (questionId) => {
  trackEvent('question_save', {
    question_id: questionId,
    event_category: 'engagement',
    event_label: 'save_question'
  });
};

// User authentication events
export const trackSignUp = (method = 'email') => {
  // Track regular event
  trackEvent('sign_up', {
    method: method,
    event_category: 'authentication',
    event_label: `signup_${method}`
  });
  
  // Track as conversion
  trackConversion('complete_registration', 0, 'USD', {
    method: method,
    event_category: 'conversion'
  });
};

export const trackSignIn = (method = 'email') => {
  trackEvent('login', {
    method: method,
    event_category: 'authentication',
    event_label: `login_${method}`
  });
};

// Navigation events with enhanced tracking
export const trackNavigation = (from, to) => {
  trackEvent('page_navigation', {
    from_page: from,
    to_page: to,
    event_category: 'navigation',
    event_label: `${from}_to_${to}`,
    navigation_time: new Date().toISOString()
  });
};

// Feature usage events with detailed tracking
export const trackFeatureUse = (featureName, actionType, details = {}) => {
  trackEvent('feature_use', {
    feature_name: featureName,
    action_type: actionType,
    event_category: 'feature',
    event_label: `${featureName}_${actionType}`,
    ...details
  });
};

// Error tracking with enhanced details
export const trackError = (errorType, errorMessage, errorDetails = {}) => {
  trackEvent('error_occurred', {
    error_type: errorType,
    error_message: errorMessage,
    event_category: 'error',
    event_label: errorType,
    stack_trace: errorDetails.stack,
    error_code: errorDetails.code,
    error_context: errorDetails.context
  });
};

// User properties with enhanced tracking
export const setUserProperties = (properties) => {
  if (isGAAvailable()) {
    window.gtag('set', 'user_properties', {
      ...properties,
      last_activity: new Date().toISOString(),
      platform: navigator.platform,
      browser: navigator.userAgent,
      account_age_days: calculateAccountAge(properties.created_at)
    });
  }
};

// Enhanced subscription tracking with conversion values
export const trackSubscriptionAttempt = (plan, currency = 'USD') => {
  const value = plan === 'yearly' ? 96 : 10;
  trackEvent('begin_checkout', {
    currency: currency,
    value: value,
    event_category: 'ecommerce',
    event_label: `checkout_${plan}`,
    items: [{
      item_name: `${plan} subscription`,
      price: value,
      currency: currency
    }]
  });
};

export const trackSubscriptionSuccess = (sessionId, plan = 'monthly', currency = 'USD') => {
  const value = plan === 'yearly' ? 96 : 10;
  const transactionId = sessionId || generateTransactionId();
  
  // Track purchase event
  trackEvent('purchase', {
    currency: currency,
    value: value,
    event_category: 'ecommerce',
    event_label: `purchase_${plan}`,
    transaction_id: transactionId,
    items: [{
      item_name: `${plan} subscription`,
      price: value,
      currency: currency
    }]
  });

  // Track as conversion
  trackConversion('premium_subscription', value, currency, {
    plan: plan,
    transaction_id: transactionId,
    event_category: 'conversion'
  });

  // Set user property
  setUserProperties({
    user_type: 'premium',
    subscription_plan: plan,
    conversion_date: new Date().toISOString()
  });
};

// Performance tracking
export const trackPerformanceMetric = (metric, value) => {
  trackEvent('performance_metric', {
    metric_name: metric,
    metric_value: value,
    event_category: 'performance',
    event_label: metric
  });
};

// Session tracking
export const trackSessionStart = () => {
  trackEvent('session_start', {
    event_category: 'session',
    event_label: 'start',
    session_id: generateSessionId()
  });
};

export const trackSessionEnd = (duration) => {
  trackEvent('session_end', {
    event_category: 'session',
    event_label: 'end',
    session_duration: duration
  });
};

// Utility functions
const generateTransactionId = () => {
  return 'txn_' + Math.random().toString(36).substr(2, 9);
};

const generateSessionId = () => {
  return 'sess_' + Math.random().toString(36).substr(2, 9);
};

// Helper function to calculate account age
const calculateAccountAge = (createdAt) => {
  if (!createdAt) return 0;
  const created = new Date(createdAt);
  const now = new Date();
  const diffTime = Math.abs(now - created);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Export conversion names for consistency
export const CONVERSIONS = {
  SIGNUP: 'complete_registration',
  PREMIUM: 'premium_subscription'
};

// Export funnel steps for consistency
export const FUNNEL_STEPS = {
  LANDING_PAGE_VIEW: 'landing_page_view',
  PRICING_PAGE_VIEW: 'pricing_page_view',
  START_REGISTRATION: 'start_registration',
  COMPLETE_REGISTRATION: 'complete_registration',
  START_SUBSCRIPTION: 'start_subscription',
  COMPLETE_SUBSCRIPTION: 'complete_subscription'
};

// Funnel step tracking
export const trackFunnelStep = (step, additionalParams = {}) => {
  trackEvent('funnel_step', {
    step_name: step,
    event_category: 'funnel',
    event_label: step,
    ...additionalParams
  });
}; 