import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { CaretDown, CaretUp, ArrowsOutSimple, ArrowsInSimple, Copy } from "@phosphor-icons/react";
import QuestionActions from './QuestionActions';

// Helper function to clean quotes from text
const cleanQuotes = (text) => {
  return text.replace(/["""'']+/g, '');
};

// Memoized QuestionActions component
const MemoizedQuestionActions = React.memo(QuestionActions);

export default function SavedQuestions() {
  const { user, supabase } = useAuth();
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedQuestions, setExpandedQuestions] = useState(new Set());
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(new Map());
  const questionRefs = useRef(new Map());

  const fetchSavedQuestions = useCallback(async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('saved_questions')
        .select(`
          id,
          created_at,
          generated_questions:question_id (
            id,
            question,
            type
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching saved questions:', error);
        return;
      }

      setSavedQuestions(data || []);
    } catch (error) {
      console.error('Error fetching saved questions:', error);
    } finally {
      setLoading(false);
    }
  }, [user, supabase]);

  useEffect(() => {
    fetchSavedQuestions();
  }, [fetchSavedQuestions]);

  const toggleQuestion = (id) => {
    setExpandedQuestions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  // Handle fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  // Toggle fullscreen
  const toggleFullscreen = async (ref) => {
    try {
      if (!isFullscreen) {
        await ref.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
    }
  };

  // Copy to clipboard function
  const copyToClipboard = async (text, questionId) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopyConfirmation(prev => new Map(prev).set(questionId, true));
      setTimeout(() => {
        setShowCopyConfirmation(prev => {
          const newMap = new Map(prev);
          newMap.delete(questionId);
          return newMap;
        });
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const renderEnergizerContent = (questionText, isExpanded, isFullscreen) => {
    try {
      const parsedContent = JSON.parse(questionText);

      return (
        <div className="space-y-4">
          <h3 className={`font-bold ${isFullscreen ? 'text-5xl' : 'text-2xl'}`}>
            {parsedContent.emoji} {parsedContent.title}
          </h3>
          <p className="text-gray-700">{parsedContent.description}</p>
          {(isFullscreen || isExpanded) && (
            <>
              <div className="bg-white p-4 rounded-lg">
                <p><strong>⏱️ Time:</strong> {parsedContent.timeFrame}</p>
                <p><strong>👥 Group Size:</strong> {parsedContent.groupSize}</p>
                <p><strong>🎯 Purpose:</strong> {parsedContent.purpose}</p>
              </div>
              <div>
                <h3 className="font-bold text-lg mb-2">Steps</h3>
                <ol className="list-decimal ml-6 space-y-2">
                  {parsedContent.steps.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ol>
              </div>
              {parsedContent.facilitatorNotes && (
                <div>
                  <h3 className="font-bold text-lg mb-2">Facilitator Notes</h3>
                  <ul className="list-disc ml-6 space-y-2">
                    {parsedContent.facilitatorNotes.map((note, index) => (
                      <li key={index}>{note}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      );
    } catch (error) {
      console.error('Error parsing energizer content:', error);
      return <p className="text-red-500">Error displaying energizer content</p>;
    }
  };

  const getQuestionTypePill = (type) => {
    switch (type) {
      case 'energizer':
        return (
          <span className="inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
            Energizer
          </span>
        );
      case 'check-in':
        return (
          <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">
            Check-In Question
          </span>
        );
      case 'check-out':
        return (
          <span className="inline-block bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded-full">
            Check-Out Question
          </span>
        );
      default:
        return null;
    }
  };

  const renderQuestion = (savedQuestion) => {
    const question = savedQuestion?.generated_questions;
    if (!question) return null;
    const isExpanded = expandedQuestions.has(savedQuestion.id);
    
    // Get or create ref for this question
    if (!questionRefs.current.has(savedQuestion.id)) {
      questionRefs.current.set(savedQuestion.id, React.createRef());
    }
    const questionRef = questionRefs.current.get(savedQuestion.id);

    return (
      <div
        key={savedQuestion.id}
        ref={questionRef}
        className={`bg-gray-100 rounded-lg p-6 relative ${
          isFullscreen && document.fullscreenElement === questionRef.current
            ? 'fixed inset-0 z-50 flex items-center justify-center m-0 rounded-none'
            : ''
        }`}
        style={isFullscreen && document.fullscreenElement === questionRef.current ? { background: 'rgb(243 244 246)' } : {}}
      >
        {/* Action buttons container */}
        <div className="absolute top-4 right-4 flex items-center gap-1">
          {/* Copy button */}
          <button
            onClick={() => {
              if (question.type === 'energizer') {
                try {
                  const energizer = typeof question.question === 'string' 
                    ? JSON.parse(question.question) 
                    : question.question;
                  
                  copyToClipboard(
                    `${energizer.emoji} ${energizer.title}\n\n${energizer.description}\n\n⏱️ Time: ${energizer.timeFrame}\n👥 Group Size: ${energizer.groupSize}\n🎯 Purpose: ${energizer.purpose}\n\nSteps:\n${energizer.steps.map((step, index) => `${index + 1}. ${step}`).join('\n')}\n\n${energizer.facilitatorNotes ? `Facilitator Notes:\n${energizer.facilitatorNotes.map(note => `• ${note}`).join('\n')}` : ''}`,
                    savedQuestion.id
                  );
                } catch (error) {
                  console.error('Error parsing energizer content:', error);
                }
              } else {
                copyToClipboard(question.question, savedQuestion.id);
              }
            }}
            className="p-1.5 text-gray-500 hover:text-gray-700 transition-colors relative"
            aria-label="Copy to clipboard"
          >
            <Copy size={20} weight="bold" />
            {/* Copy confirmation tooltip */}
            {showCopyConfirmation.get(savedQuestion.id) && (
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 bg-black text-white text-xs px-2 py-1 rounded whitespace-nowrap">
                Copied!
              </span>
            )}
          </button>

          {/* Fullscreen button */}
          <button
            onClick={() => toggleFullscreen(questionRef)}
            className="p-1.5 text-gray-500 hover:text-gray-700 transition-colors"
            aria-label={isFullscreen && document.fullscreenElement === questionRef.current ? "Exit fullscreen" : "Enter fullscreen"}
          >
            {isFullscreen && document.fullscreenElement === questionRef.current ? (
              <ArrowsInSimple size={20} weight="bold" />
            ) : (
              <ArrowsOutSimple size={20} weight="bold" />
            )}
          </button>
        </div>

        <div className={`${isFullscreen && document.fullscreenElement === questionRef.current ? 'max-w-4xl mx-auto' : ''}`}>
          <div className="mb-4">
            {getQuestionTypePill(question.type)}
          </div>
          
          {question.type === 'energizer' ? (
            <div>
              {renderEnergizerContent(
                question.question,
                isExpanded,
                isFullscreen && document.fullscreenElement === questionRef.current
              )}
              {!isFullscreen && (
                <button
                  onClick={() => toggleQuestion(savedQuestion.id)}
                  className="flex items-center gap-2 text-blue-600 mt-4 hover:text-blue-700"
                >
                  {isExpanded ? 'Show less' : 'Show more'}
                  {isExpanded ? <CaretUp size={20} /> : <CaretDown size={20} />}
                </button>
              )}
            </div>
          ) : (
            <div className="text-center">
              <h2 className={`font-bold ${isFullscreen && document.fullscreenElement === questionRef.current ? 'text-6xl' : 'text-2xl'}`}>
                &quot;{cleanQuotes(question.question)}&quot;
              </h2>
            </div>
          )}
          
          {(!isFullscreen || document.fullscreenElement !== questionRef.current) && (
            <>
              <MemoizedQuestionActions 
                question={question} 
                onVote={fetchSavedQuestions}
              />
              <div className="text-center mt-4 text-sm text-gray-500">
                {savedQuestion.voteCount || 0} helpful {(savedQuestion.voteCount === 1) ? 'vote' : 'votes'}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
          <h1 className="text-3xl font-bold mb-6">Saved Questions</h1>
          <p className="text-gray-500 text-center py-8">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
      <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
        <h1 className="text-3xl font-bold mb-6">
          Saved Questions ({savedQuestions.length})
        </h1>
        {savedQuestions.length === 0 ? (
          <p className="text-gray-500 text-center py-8">No saved questions yet.</p>
        ) : (
          <div className="space-y-6">
            {savedQuestions.map(renderQuestion)}
          </div>
        )}
      </div>
    </div>
  );
} 