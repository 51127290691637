import { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSubscriptionStatus } from '../contexts/SubscriptionContext';
import { 
  getOpenAIClient, 
  getSystemPrompt, 
  getUserPrompt, 
  getVarietyElements,
  QUESTION_STRUCTURES,
  TOPIC_CATEGORIES,
  ENERGIZER_TEMPLATES,
  cleanQuestionContent,
  cleanEnergizerContent
} from '../utils/openai';

// Constants for question generation limits
const MAX_FREE_GENERATIONS_ANONYMOUS = 5;  // Non-logged in users
const MAX_FREE_GENERATIONS_REGISTERED = 25; // Registered users

// Cookie management functions
const COOKIE_NAME = 'question_generations';

const getCookieValue = () => {
  try {
    const cookie = document.cookie
      .split('; ')
      .find(row => row.startsWith(COOKIE_NAME));
    
    if (cookie) {
      const value = JSON.parse(decodeURIComponent(cookie.split('=')[1]));
      // Check if the stored date is from a different day
      if (new Date(value.date).toDateString() !== new Date().toDateString()) {
        return null; // Will trigger a reset
      }
      return value;
    }
    return null;
  } catch (error) {
    console.error('Error parsing generation cookie:', error);
    return null;
  }
};

const setGenerationsCookie = (count) => {
  const value = {
    count,
    date: new Date().toISOString()
  };
  // Set cookie to expire at the end of the current day
  const midnight = new Date();
  midnight.setHours(23, 59, 59, 999);
  
  document.cookie = `${COOKIE_NAME}=${encodeURIComponent(JSON.stringify(value))}; expires=${midnight.toUTCString()}; path=/; SameSite=Strict`;
};

// Main question generation function
export const generateOpenAIQuestion = async (type, mood, complexity, prevQuestions = []) => {
  try {
    console.log('🤖 OpenAI Generation');
    console.log('📝 Generating with params:', { type, mood, complexity });
    
    const openai = getOpenAIClient();
    const variety = getVarietyElements(prevQuestions);
    const systemPrompt = getSystemPrompt(type, variety);
    const userPrompt = getUserPrompt(type, mood, complexity, prevQuestions);

    if (type === 'energizer') {
      let completion;
      try {
        completion = await openai.chat.completions.create({
          model: "gpt-4-turbo-preview",
          messages: [
            { 
              role: "system", 
              content: `You are an expert facilitator. Return ONLY valid JSON with no markdown. Format:
{
  "title": "Brief title",
  "emoji": "🎯",
  "category": "movement",
  "description": "One sentence description",
  "timeFrame": "5-10 minutes",
  "groupSize": "4-12 people",
  "materials": "None required",
  "purpose": "One clear goal",
  "steps": ["Step 1", "Step 2", "Step 3"],
  "facilitatorNotes": ["Note 1", "Note 2"]
}`
            },
            { role: "user", content: userPrompt }
          ],
          temperature: 0.7,
          max_tokens: 800,
        });

        console.log('Raw energizer response:', completion.choices[0].message.content);
        
        // Parse and validate the response
        const energizerContent = JSON.parse(completion.choices[0].message.content);
        
        // Validate required fields
        const requiredFields = ['title', 'emoji', 'category', 'description', 'timeFrame', 'groupSize', 'steps'];
        const missingFields = requiredFields.filter(field => !energizerContent[field]);
        
        if (missingFields.length > 0) {
          throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
        }

        return energizerContent;

      } catch (parseError) {
        console.error('Error parsing energizer response:', parseError);
        console.log('Failed response:', completion?.choices[0]?.message?.content);
        throw new Error('Failed to generate valid energizer format');
      }
    } else {
      // For check-in and check-out questions
      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages: [
          { role: "system", content: systemPrompt },
          { role: "user", content: userPrompt }
        ],
        temperature: 1,
        max_tokens: 500
      });

      const response = completion.choices[0]?.message?.content;
      if (!response) {
        throw new Error('No response from OpenAI');
      }

      const cleanedResponse = response.trim();
      console.log(`✅ Generated ${type} question:`, cleanedResponse);
      
      // For background generation, return the content directly
      return cleanedResponse;
    }
  } catch (error) {
    console.error('Error in generateOpenAIQuestion:', error);
    throw new Error(`Failed to generate ${type}: ${error.message}`);
  }
};

// Add this function after the imports
const logDatabaseStatus = async (supabase) => {
  try {
    // Get total counts for each type
    const types = ['check-in', 'check-out', 'energizer'];
    const stats = {};

    for (const type of types) {
      // Get total count
      const { count: total } = await supabase
        .from('generated_questions')
        .select('*', { count: 'exact', head: true })
        .eq('type', type);

      // Get unserved count
      const { count: unserved } = await supabase
        .from('generated_questions')
        .select('*', { count: 'exact', head: true })
        .eq('type', type)
        .is('is_served', false);

      stats[type] = { total, unserved };
    }

    // Helper function to format type name
    const formatTypeName = (type) => {
      return type.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('-');
    };

    console.log('📊 Database Status Overview:');
    console.log('┌────────────┬───────────┬────────────┐');
    console.log('│ Type       │   Total   │  Unserved  │');
    console.log('├────────────┼───────────┼────────────┤');
    for (const [type, counts] of Object.entries(stats)) {
      console.log(
        `│ ${formatTypeName(type).padEnd(10)} │ ${String(counts.total).padStart(9)} │ ${String(counts.unserved).padStart(10)} │`
      );
    }
    console.log('└────────────┴───────────┴────────────┘');
  } catch (error) {
    console.error('Error getting database status:', error);
  }
};

// Main hook implementation
export default function useQuestionGenerator() {
  const { supabase, user } = useAuth();
  const { subscriptionStatus } = useSubscriptionStatus();
  const [selectedType, setSelectedType] = useState('check-in');
  const [selectedMood, setSelectedMood] = useState('curious');
  const [selectedComplexity, setSelectedComplexity] = useState('moderate');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedQuestion, setGeneratedQuestion] = useState(null);
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [error, setError] = useState(null);

  // Initialize generations left from cookie or default value
  const [generationsLeft, setGenerationsLeft] = useState(() => {
    if (subscriptionStatus?.subscription_status === 'premium') return Infinity;
    
    const storedValue = getCookieValue();
    if (storedValue) {
      return storedValue.count;
    }
    
    // Set initial value
    const initialValue = user ? MAX_FREE_GENERATIONS_REGISTERED : MAX_FREE_GENERATIONS_ANONYMOUS;
    setGenerationsCookie(initialValue);
    return initialValue;
  });

  // Update cookie whenever generations left changes
  useEffect(() => {
    if (generationsLeft !== Infinity && generationsLeft >= 0) {
      setGenerationsCookie(generationsLeft);
    }
  }, [generationsLeft]);

  // Reset generations at midnight
  useEffect(() => {
    const checkDayChange = () => {
      const storedValue = getCookieValue();
      if (!storedValue) {
        const newValue = user ? MAX_FREE_GENERATIONS_REGISTERED : MAX_FREE_GENERATIONS_ANONYMOUS;
        setGenerationsLeft(newValue);
        setGenerationsCookie(newValue);
      }
    };

    // Check on mount and set up interval
    checkDayChange();
    const interval = setInterval(checkDayChange, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [user]);

  const handleTypeChange = useCallback((type) => {
    setSelectedType(type);
  }, []);

  const handleMoodChange = useCallback((mood) => {
    setSelectedMood(mood);
  }, []);

  const handleComplexityChange = useCallback((complexity) => {
    setSelectedComplexity(complexity);
  }, []);

  // Get an unserved question from the database
  const getUnservedQuestion = useCallback(async () => {
    if (!supabase) return null;

    try {
      console.log('🔍 Fetching unserved question for:', { selectedType, selectedMood, selectedComplexity });
      
      // First, let's check how many unserved questions exist total
      const { count: totalUnserved } = await supabase
        .from('generated_questions')
        .select('*', { count: 'exact', head: true })
        .is('is_served', false)
        .eq('is_public', true);
        
      console.log('📊 Total unserved questions in database:', totalUnserved);

      // Then check how many match our type
      const { count: typeMatches } = await supabase
        .from('generated_questions')
        .select('*', { count: 'exact', head: true })
        .eq('type', selectedType)
        .is('is_served', false)
        .eq('is_public', true);
        
      console.log(`📊 Unserved questions matching type '${selectedType}':`, typeMatches);

      let query = supabase
        .from('generated_questions')
        .select('id, question, type, mood, complexity, votes_questions(vote_type)')
        .eq('type', selectedType)
        .is('is_served', false)
        .eq('is_public', true);

      // For logged-in users, also check ownership
      if (user) {
        query = query.or(`user_id.is.null,user_id.eq.${user.id}`);
      }

      // Try to get questions matching all criteria
      const { data: exactMatches, error: fetchError } = await query
        .eq('mood', selectedMood)
        .eq('complexity', selectedComplexity);

      if (fetchError) {
        console.error('❌ Database error:', fetchError);
        throw fetchError;
      }

      // If we found exact matches, randomly select one
      if (exactMatches && exactMatches.length > 0) {
        const randomIndex = Math.floor(Math.random() * exactMatches.length);
        const selectedMatch = exactMatches[randomIndex];
        console.log('✅ Found exact match:', selectedMatch);
        const cleanedQuestion = selectedType === 'energizer' 
          ? cleanEnergizerContent(selectedMatch.question)
          : cleanQuestionContent(selectedMatch.question);

        return {
          ...selectedMatch,
          question: cleanedQuestion
        };
      }

      // If no exact match, try to find high-scoring questions of the same type
      const { data: highScoreMatches, error: scoreError } = await supabase
        .from('generated_questions')
        .select('id, question, type, mood, complexity, votes_questions(vote_type)')
        .eq('type', selectedType)
        .is('is_served', false)
        .eq('is_public', true);

      if (scoreError) {
        console.error('❌ Error fetching high-score matches:', scoreError);
        throw scoreError;
      }

      if (highScoreMatches && highScoreMatches.length > 0) {
        // Calculate scores
        const scoredQuestions = highScoreMatches
          .map(q => ({
            ...q,
            score: (q.votes_questions || [])
              .reduce((acc, v) => acc + (v.vote_type === 'helpful' ? 1 : -1), 0)
          }))
          .filter(q => q.score >= 0); // Only keep non-negative scores

        if (scoredQuestions.length > 0) {
          // Group questions by score
          const scoreGroups = {};
          scoredQuestions.forEach(q => {
            if (!scoreGroups[q.score]) scoreGroups[q.score] = [];
            scoreGroups[q.score].push(q);
          });

          // Get the highest score
          const highestScore = Math.max(...Object.keys(scoreGroups).map(Number));
          
          // Randomly select from the questions with the highest score
          const topQuestions = scoreGroups[highestScore];
          const randomIndex = Math.floor(Math.random() * topQuestions.length);
          const bestMatch = topQuestions[randomIndex];

          console.log('✅ Found high-scoring question:', bestMatch);
          const cleanedQuestion = selectedType === 'energizer' 
            ? cleanEnergizerContent(bestMatch.question)
            : cleanQuestionContent(bestMatch.question);

          return {
            ...bestMatch,
            question: cleanedQuestion
          };
        }
      }

      console.log('❌ No suitable questions found in database');
      return null;
    } catch (err) {
      console.error('Error in getUnservedQuestion:', err);
      return null;
    }
  }, [supabase, user, selectedType, selectedMood, selectedComplexity]);

  // Generate background questions to maintain pool
  const generateBackgroundQuestions = useCallback(async () => {
    if (!supabase || !user) return;

    try {
      console.log('🔄 Background Generation Process');
      console.log('🎯 Starting background generation of 3 questions...');
      const questions = [];
      
      for (let i = 0; i < 3; i++) {
        console.log(`📝 Generating Question ${i + 1}/3`);
        
        const generatedContent = await generateOpenAIQuestion(
          selectedType,
          selectedMood,
          selectedComplexity,
          previousQuestions
        );

        // Ensure we have valid content before proceeding
        if (!generatedContent) {
          console.error('❌ No content generated for background question');
          continue;
        }

        // For check-ins and check-outs, we need to extract the content string
        const questionContent = selectedType === 'energizer' 
          ? JSON.stringify(generatedContent)  // Energizers are already in correct format
          : (typeof generatedContent === 'string' 
              ? generatedContent 
              : generatedContent.content || generatedContent.toString());

        // Validate we have content before adding to questions array
        if (!questionContent) {
          console.error('❌ Failed to extract valid question content');
          continue;
        }

        console.log(`✅ Generated ${selectedType} question:`, questionContent.substring(0, 100) + '...');

        questions.push({
          user_id: user.id,
          type: selectedType,
          mood: selectedMood,
          complexity: selectedComplexity,
          question: questionContent,
          created_at: new Date().toISOString(),
          is_served: false,
          is_public: true
        });
      }

      // Only proceed with insert if we have valid questions
      if (questions.length === 0) {
        console.error('❌ No valid questions generated for background insertion');
        return;
      }

      console.log(`📥 Attempting to save ${questions.length} background questions to database`);
      
      const { data: insertedData, error: insertError } = await supabase
        .from('generated_questions')
        .insert(questions)
        .select();

      if (insertError) {
        console.error('❌ Error inserting background questions:', insertError);
        throw insertError;
      }

      console.log(`✨ Successfully saved ${insertedData.length} background questions to pool:`, 
        insertedData.map(q => ({
          id: q.id,
          type: q.type,
          preview: typeof q.question === 'string' 
            ? q.question.substring(0, 50) + '...' 
            : 'Energizer content'
        }))
      );
      
      // Add status overview after successful background generation
      await logDatabaseStatus(supabase);
    } catch (err) {
      console.error('❌ Background generation error:', err);
    }
  }, [supabase, user, selectedType, selectedMood, selectedComplexity, previousQuestions, generateOpenAIQuestion]);

  // Fetch previous questions
  const fetchPreviousQuestions = useCallback(async () => {
    if (!user) {
      console.log('Skipping previous questions fetch - no user');
      return;
    }

    try {
      const { data: questionsData, error: fetchError } = await supabase
        .from('generated_questions')
        .select(`
          id, 
          question, 
          type, 
          mood, 
          complexity, 
          created_at,
          votes_questions(vote_type, user_id)
        `)
        .eq('type', selectedType)
        .eq('is_public', true)  // Only show public questions
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;

      if (questionsData) {
        const formattedQuestions = questionsData
          .map(q => ({
            id: q.id,
            content: q.question,
            type: q.type,
            mood: q.mood,
            complexity: q.complexity,
            voteCount: (q.votes_questions || [])
              .reduce((acc, v) => acc + (v.vote_type === 'helpful' ? 1 : -1), 0),
            userVote: (q.votes_questions || [])
              .find(v => v.user_id === user.id)?.vote_type || null,
          }))
          // Filter questions with at least one vote
          .filter(q => q.voteCount > 0)
          // Sort by vote count in descending order
          .sort((a, b) => b.voteCount - a.voteCount)
          // Take top 20 most popular
          .slice(0, 20);

        setPreviousQuestions(formattedQuestions);
      }
    } catch (error) {
      console.error('Failed to fetch previous questions:', error);
      setError('Failed to fetch previous questions.');
    }
  }, [supabase, user, selectedType]);

  // Main generate question function
  const generateQuestion = useCallback(async () => {
    console.log('🎯 Starting Question Generation - User status:', !!user);
    setError(null);
    setIsLoading(true);

    // Check if user has generations left
    if (generationsLeft <= 0 && subscriptionStatus?.subscription_status !== 'premium') {
      setError('You have reached your free generation limit. Please upgrade to premium for unlimited questions.');
      setIsLoading(false);
      return;
    }

    try {
      // Try to get an unserved question from the database first
      const unservedQuestion = await getUnservedQuestion();
      
      if (unservedQuestion) {
        console.log('✨ Using unserved question from pool:', unservedQuestion.id);
        
        // Mark as served
        const { error: updateError } = await supabase
          .from('generated_questions')
          .update({ 
            is_served: true,
            served_at: new Date().toISOString()
          })
          .eq('id', unservedQuestion.id);

        if (updateError) {
          console.error('Error marking question as served:', updateError);
          throw updateError;
        }

        setGeneratedQuestion({
          id: unservedQuestion.id,
          ...(selectedType === 'energizer' 
            ? unservedQuestion.question
            : { content: unservedQuestion.question }),
          type: selectedType,
          mood: selectedMood,
          complexity: selectedComplexity
        });

        // Decrement generations left counter for both database and OpenAI generations
        setGenerationsLeft(prev => Math.max(0, prev - 1));

        // Generate new questions in the background only for logged-in users
        if (user) {
          generateBackgroundQuestions().catch(console.error);
          // Add status logging after background generation
          await logDatabaseStatus(supabase);
        }
        return;
      }

      // If no unserved question found, generate new one with OpenAI
      console.log('🤖 Generating new question with OpenAI');
      const openai = getOpenAIClient();
      const variety = getVarietyElements(previousQuestions);
      const systemPrompt = getSystemPrompt(selectedType, variety);
      const userPrompt = getUserPrompt(selectedType, selectedMood, selectedComplexity, previousQuestions);
      
      let response;
      if (selectedType === 'energizer') {
        const completion = await openai.chat.completions.create({
          model: "gpt-4-turbo-preview",
          messages: [
            { 
              role: "system", 
              content: `You are an expert facilitator. Return ONLY valid JSON with no markdown. Format:
{
  "title": "Brief title",
  "emoji": "🎯",
  "category": "movement",
  "description": "One sentence description",
  "timeFrame": "5-10 minutes",
  "groupSize": "4-12 people",
  "materials": "None required",
  "purpose": "One clear goal",
  "steps": ["Step 1", "Step 2", "Step 3"],
  "facilitatorNotes": ["Note 1", "Note 2"]
}`
            },
            { role: "user", content: userPrompt }
          ],
          temperature: 0.7,
          max_tokens: 800,
        });
        
        response = completion.choices[0]?.message?.content;
        if (!response) throw new Error('No response from OpenAI');
        
        try {
          const energizerContent = JSON.parse(response);
          // Validate required fields
          const requiredFields = ['title', 'emoji', 'category', 'description', 'timeFrame', 'groupSize', 'steps'];
          const missingFields = requiredFields.filter(field => !energizerContent[field]);
          
          if (missingFields.length > 0) {
            throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
          }
          setGeneratedQuestion(energizerContent);
        } catch (parseError) {
          console.error('Error parsing energizer response:', parseError);
          console.log('Failed response:', response);
          throw new Error('Failed to generate valid energizer format');
        }
      } else {
        // For check-in and check-out questions
        const completion = await openai.chat.completions.create({
          model: "gpt-4",
          messages: [
            { role: "system", content: systemPrompt },
            { role: "user", content: userPrompt }
          ],
          temperature: 1,
          max_tokens: 500
        });

        response = completion.choices[0]?.message?.content;
        if (!response) throw new Error('No response from OpenAI');
        
        setGeneratedQuestion({ content: response.trim() });
      }

      // Decrement generations left counter
      setGenerationsLeft(prev => Math.max(0, prev - 1));

      // For logged-in users, generate background questions
      if (user) {
        generateBackgroundQuestions().catch(console.error);
        // Add status logging after background generation
        await logDatabaseStatus(supabase);
      }

    } catch (error) {
      console.error('Error generating question:', error);
      setError(error.message || 'Failed to generate question. Please try again.');
      setGeneratedQuestion(null);
    } finally {
      setIsLoading(false);
    }
  }, [
    user,
    selectedType,
    selectedMood,
    selectedComplexity,
    previousQuestions,
    getUnservedQuestion,
    generateBackgroundQuestions,
    supabase,
    setError,
    subscriptionStatus
  ]);

  return {
    generationsLeft,
    selectedType,
    selectedMood,
    selectedComplexity,
    isLoading,
    generatedQuestion,
    error,
    previousQuestions,
    handleTypeChange,
    handleMoodChange,
    handleComplexityChange,
    generateQuestion,
    fetchPreviousQuestions
  };
} 